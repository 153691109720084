import { BILLING_INTERVAL, TIER } from '../constants';
import { loadStripe } from '@stripe/stripe-js';
import { fetchData, getCookieValue, makeHeaders, makeParams } from './apiUtils';
import { API_URLS } from '../apiUrls';
import { FetchReturn } from '../store';
import { cabCaptureException } from './logging';

export const getCheckoutSessionId = async (
  discountCode: string, tier: TIER | null, frequency: BILLING_INTERVAL | null, 
  quantity: number | null, source: string, redirectSuccess?: string, cancelUrl?: string,
): Promise<FetchReturn<{session_id: string | null, active_sub_id: string | null}> | undefined> => {
  // We are not dealing with coupons at this point, but keeping the structure in place here in case we do.
  const couponCode = '';
  const discountPercent = 0;
  //const couponCode = discountCode in COUPONS ? discountCode : defaultCouponCode;
  //const discountPercent = Math.floor(COUPONS[couponCode].discountPercent*100);
  const headers = await makeHeaders(true);
  const fbp = getCookieValue('_fbp');
  const fbc = getCookieValue('_fbc');

  const paramString = makeParams({
    mode: 'subscription', 
    tier: tier ?? undefined, 
    frequency: frequency ?? undefined, 
    quantity: quantity ?? undefined, 
    coupon: couponCode, 
    discountPercent, 
    fbp, 
    fbc,
    successUrl: redirectSuccess, 
    cancelUrl, 
    source,
  });

  const sessionRes = await fetchData<{session_id: string | null, active_sub_id: string | null}>(
    API_URLS.PAYMENT_SESSION + paramString, {headers, method: 'GET'}
  );
  if (sessionRes.status === 200 ) {
    return sessionRes;
  } else if (sessionRes.status === 400) {
    return sessionRes;
  }
    
};

export const redirectToCheckout = async (sessionId: string): Promise<void> => {
  const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY as string);
  if (!stripe) {
    const error = Error('Payment attempted but Stripe not initialized. Session ID = ' + sessionId);
    cabCaptureException(error);
    throw error;
  } else {
    stripe.redirectToCheckout({ sessionId });
  }
};

export const getBillingSession = async (): Promise<string | undefined> => {
  const headers = await makeHeaders(true);
  const res = await fetchData(API_URLS.BILLING_SESSION, {headers, method: 'POST'});
  if (res.status === 200) {
    return res.data;
  }
};

export const trackPurchase = (data: {
  transaction_id: string;
  value: number;
  currency: string;
  items: {
    id: number;
    name: string;
    brand: string;
    category: string;
    price: number;
    quantity: number;
  }[];
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'purchase',
    'transactionId': data.transaction_id,
    'transactionAffiliation': 'Cabinet',
    'transactionTotal': data.value,
    'transactionCurrency': data.currency,
    'transactionTax': 0.00,
    'transactionShipping': 0.00,
    'transactionProducts': [{
      'sku': data.items[0].name,
      'name': data.items[0].name,
      'category': data.items[0].category,
      'price': data.items[0].price,
      'quantity': data.items[0].quantity
    }]
  });
};
