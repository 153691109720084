import { Stack, Box, FormControl, FormLabel, FormHelperText } from '@mui/material';
import { CabButton, CabIcon, CabTextInput } from '@CabComponents';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { IoAdd, IoClose } from 'react-icons/io5';


interface Props {
  name: string;
  control: Control;
  onBlur?: () => void;
  onDelete?: () => void;
  touchedFields?: ({ name?: boolean; location?: boolean }|undefined)[];
}

const LocationPresetWidget = ({ name, control, onBlur, onDelete, touchedFields }: Props) => {
  const { fields: locationFields, append, remove } = useFieldArray({ control, name });

  return (
    <Stack gap={1}>
      {locationFields.map((locationField, idx) => (
        <Box width="100%" display="flex" flexDirection="row" gap={2}
          alignItems="center" key={locationField.id}>
          <FormControl sx={{ flex: 1 }}>
            <FormLabel>Location name</FormLabel>
            <Controller
              name={`${name}.${idx}.name`}
              control={control}
              rules={{ required: true }} 
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <>
                  <CabTextInput {...field} placeholder="Bob's Phone Number" onBlur={() => {
                    // only call onBlur once both fields are touched
                    if (onBlur && touchedFields?.[idx]?.location) onBlur();
                    field.onBlur();
                  }} />
                  <FormHelperText error>{error && 'Location name is required'}</FormHelperText>
                </>
              )}
            />
          </FormControl>

          <FormControl sx={{ flex: 1 }}>
            <FormLabel>Location value</FormLabel>
            <Controller
              name={`${name}.${idx}.location`}
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <>
                  <CabTextInput {...field} placeholder="1-212-123-4567" onBlur={() => {
                    // only call onBlur once both fields are touched
                    if (onBlur && touchedFields?.[idx]?.name) onBlur();
                    field.onBlur();
                  }} />
                  <FormHelperText error>{error && 'Location value is required'}</FormHelperText>
                </>
              )}
            />
          </FormControl>
          <CabIcon
            Icon={IoClose}
            onClick={() => {
              remove(idx);
              if (onDelete) onDelete();
            }}
            sx={{ paddingTop: 2 }}
          />
        </Box>
      ))}

      <CabButton
        buttonType="tertiary"
        onClick={() => append({ name: '', location: '' })}
        sx={{ width: 190 }}
        icon={<CabIcon Icon={IoAdd} />}
      >
        Add New Location
      </CabButton>
    </Stack>
  );
};

export default LocationPresetWidget;
